import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import mpd09 from "../images/mpd_small.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Mpd() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">МПД СВЛ ТР</h1>

          <div
            className="ImgWrapperFlow float-left"
            style={{ marginLeft: -58 }}
            onClick={() => {
              openLightbox("mpd.png")
            }}
          >
            <img src={mpd09} />
          </div>

          <p>
            Модуль выполнен в бескорпусном исполнении и входит в состав
            современной отечественной микропроцессорной системы управления и
            диагностики локомотива (МПСУиД).
          </p>
          <p>
            Модуль МПД СВЛ ТР применяется на тяговом и моторвагонном подвижном
            составе, эксплуатируемом на сети железных дорог с шириной колеи 1520
            мм, электрифицированных на постоянном токе напряжением 3000 В и
            переменном токе напряжением 25000 В с частотой 50 Гц. В настоящее
            время в эксплуатации находится около 3 тысяч устройств,
            установленных на 6 сериях тягового и 4 сериях моторвагонного
            подвижного состава.
          </p>

          <p>
            Модуль МПД СВЛ ТР устанавливается в системный шкаф безопасного
            локомотивного объединенного комплекса БЛОК.
          </p>
          <p className="clear-both">
            Модуль МПД СВЛ ТР выпускается серийно по техническим условиям и
            отвечает требованиям ГОСТ 15150-69, ГОСТ 17516.1-90, ГОСТ
            33435-2015.
          </p>
          <p>
            Модуль МПД СВЛ ТР функционирует под управлением операционной системы
            на базе ядра Linux 3.10 с использование дополнительного прикладного
            программного обеспечения: СВЛ ТР, ViPNet Client 4 for Linux,
            КриптоПро CSP, PCSC-Lite, pppd, wvdial, ntpupdate и реализует:
          </p>
          <ul>
            <li>
              по интерфейсам стандарта CAN, последовательному интерфейсу
              стандарта RS-232 двусторонний информационный обмен с большинством
              серийно устанавливаемыми на отечественном подвижном составе
              локомотивными бортовыми программно- аппаратными комплексами
              управления локомотивом, обеспечения безопасности движения,
              автоведения, регистрации параметров;
            </li>
            <li>
              по мобильной связи стандарта GSM общего применения или по
              технологической ремонтно-оперативной радиосвязи ОАО «Российские
              железные дороги» на базе сетей подвижной связи стандарта GSM (РОРС
              GSM/GPRS) с поддержкой технологии пакетной передачи данных и
              скорости для GPRS (class 10), EDGE, UMTS, LTE и т.д., с
              обеспечением защиты передаваемых по радиоканалу данных от
              несанкционированного доступа с помощью средства криптографической
              защиты информации «Программный комплекс VipNet Client 4 for
              Linux», имеющего сертификат соответствия ФСБ России двусторонний
              информационный обмен с коммуникационным сервером, расположенным в
              ГВЦ ОАО «РЖД»;
            </li>
            <li>
              удалённое обновление встроенного программного обеспечения модуля
              МПД СВЛ ТР с коммуникационного сервера с обеспечением защиты
              информации от несанкционированного доступа с использованием СКЗИ –
              «Программный комплекс ViPNet Client 4 for Linux»;
            </li>
            <li>
              передача обновлений встроенного программного обеспечения и баз
              данных для блоков из состава бортовых локомотивных программно-
              аппаратных комплексов через коммуникационный сервер с обеспечением
              защиты информации;
            </li>
          </ul>
          <table>
            <tbody>
              <tr>
                <td>Режим работы</td>
                <td>непрерывный, круглосуточный</td>
              </tr>
              <tr>
                <td>Напряжение питания, В</td>
                <td>от 40 до 65</td>
              </tr>
              <tr>
                <td>Средний потребляемый ток, мА</td>
                <td>600</td>
              </tr>
              <tr>
                <td>Импульсный потребляемый ток, не более, А</td>
                <td>1,5</td>
              </tr>
              <tr>
                <td>Волновое сопротивление нагрузки, Ом</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Допустимый диапазон рабочих температур, ºС</td>
                <td>от минус 40 до плюс 60</td>
              </tr>
              <tr>
                <td colSpan="2">Максимальная скорость передачи данных*</td>
              </tr>
              <tr>
                <td className="pl-6">– в режимах EDGE, UMTS, кбит/с</td>
                <td>384</td>
              </tr>
              <tr>
                <td className="pl-6">– в режиме HSPA, Мбит/с</td>
                <td>7,2</td>
              </tr>
              <tr>
                <td>Рабочие диапазоны частот, МГц</td>
                <td>
                  от 850 до 900, <br></br> от 1800 до 1900
                </td>
              </tr>
              <tr>
                <td>Режим передачи</td>
                <td>полудуплексный</td>
              </tr>
              <tr>
                <td>Скорость работы по интерфейсу CAN</td>
                <td>регулируется программно</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  Система подогрева в модулях исполнений ТИЖМ.687252.002-05,
                  ТИЖМ.687252.002-06, ТИЖМ.687252.002-07
                </td>
                <td>встроенная</td>
              </tr>
              <tr>
                <td>Средний срок службы, не менее, лет</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Средняя наработка на отказ, не менее, ч</td>
                <td>30 000</td>
              </tr>
              <tr>
                <td>Среднее время восстановления, не более, c</td>
                <td>60</td>
              </tr>
              <tr>
                <td>Тип антенного разъёма</td>
                <td>SMA</td>
              </tr>
              <tr>
                <td>Габаритные размеры (В x Д x Ш), не более, мм</td>
                <td>129,0 × 194,0 × 30,5</td>
              </tr>
              <tr>
                <td>Вес не более, кг</td>
                <td>0,25</td>
              </tr>
            </tbody>
          </table>

          <p>
            * - cкорость передачи данных зависит от загруженности сети GSM и
            условий предоставления услуги передачи данных оператором сотовой
            связи
          </p>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}

import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import tsn_box from "../images/tsn_box.png"
import tsn_back from "../images/tsn_back.png"
import tsn_small from "../images/tsn_small.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Tsn() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">ТСН «Урал»</h1>
          <div
            className="ImgWrapperFlow float-left"
            style={{ marginLeft: -22, marginRight: 20, marginTop: -15 }}
            onClick={() => {
              openLightbox("tsn.png")
            }}
          >
            <img src={tsn_small} />
          </div>
          <p style={{ paddingTop: 20 }}>
            ТСН «УРАЛ» является транспортным сетевым накопителем промышленного
            исполнения и применяется на подвижном составе, эксплуатируемом на
            сети железных дорог.
          </p>

          <p>
            В настоящее время ТСН «УРАЛ» входит в состав системы видеонаблюдения
            и оповещения пассажиров (СВОП) для электропоездов Ласточка.
          </p>
          <div className="clear-both"></div>
          <div
            className="ImgWrapperFlow float-right"
            style={{ margin: "0px 0 0 10px" }}
            onClick={() => {
              openLightbox("tsn_back.png")
            }}
          >
            <img src={tsn_back} />
          </div>
          <p
            style={{ marginTop: 40, letterSpacing: "0.1px" }}
            className="margin-reset"
          >
            Производство накопителя осуществляется по техническим условиям
            ТИЖМ.466226.002 ТУ с применением импортонезависимых элементов.
          </p>
          <p>
            Аппаратная часть выполнена на импортонезависимой элементной базе.
          </p>
          <div
            className="ImgWrapperFlow float-left"
            style={{ margin: "-40px 30px 0 -30px" }}
            onClick={() => {
              openLightbox("tsn_box.png")
            }}
          >
            <img src={tsn_box} />
          </div>
          <div className=" pb" style={{ clear: "right", marginTop: 10 }}></div>
          <p>
            В качестве операционной системы ТСН используется ОС Astra Linux,
            выполненная исключительно с применением сертифицированных
            компонентов программного обеспечения, разработанных в России.
          </p>
          <p>
            В зависимости от выполняемых задач возможно подобрать необходимые
            вычислительные мощности, выводы интерфейсов, количество слотов под
            жесткие диски и номинальное напряжение питания.
          </p>
          <p>Разработан и произведен в Москве.</p>
          <table className="tbl-params">
            <tbody>
              <tr>
                <td>Тип процессора</td>
                <td>ARM</td>
              </tr>
              <tr>
                <td>Тактовая частота</td>
                <td>2 ГГц</td>
              </tr>
              <tr>
                <td>ОЗУ</td>
                <td>4Гб DDR4</td>
              </tr>
              <tr>
                <td>Внешние интерфейсы</td>
                <td></td>
              </tr>
              <tr>
                <td className="pl-6">– COM</td>
                <td>2шт / изолированные RS-232</td>
              </tr>
              <tr>
                <td className="pl-6">– USB</td>
                <td>2шт / USB 2.0; 1шт / USB 3.0</td>
              </tr>
              <tr>
                <td className="pl-6">– Ethernet</td>
                <td>2шт / GE (М12)</td>
              </tr>
              <tr>
                <td>Жесткие диски</td>
                <td>до 6шт SATA III с возможностью замены с лицевой панели</td>
              </tr>
              <tr>
                <td>Индикация состояния интерфейсов</td>
                <td>ЖКИ дисплей</td>
              </tr>
              <tr>
                <td>Номинальное напряжение питания постоянного тока</td>
                <td>24 / 50 / 75 / 110 В</td>
              </tr>
              <tr>
                <td>Номинальная потребляемая мощность</td>
                <td>60 Вт</td>
              </tr>
              <tr>
                <td>Тип охлаждения</td>
                <td>безвентиляторный с пассивным охлаждением</td>
              </tr>
              <tr>
                <td>Возможность крепления в стойке / стандарт</td>
                <td>да / ГОСТ 28601.1, ГОСТ 28601.2</td>
              </tr>
              <tr>
                <td>Диапазон рабочих температур</td>
                <td>от -40 до +55°С</td>
              </tr>
              <tr>
                <td>Диапазон температур хранения</td>
                <td>от -50 до +55°С</td>
              </tr>
              <tr>
                <td>Относительная влажность</td>
                <td>от 5 до 95% (без конденсации)</td>
              </tr>
              <tr>
                <td>Степень защиты корпуса</td>
                <td>IP40</td>
              </tr>
              <tr>
                <td>Габаритные размеры (ДШВ)</td>
                <td>300 × 220 × 105 мм</td>
              </tr>
              <tr>
                <td>Вес не более</td>
                <td>8,0 кг</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}

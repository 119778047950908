import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import mpdn from "../images/mpdn_small.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Mpdn() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">МПД-Н</h1>
          <div
            className="ImgWrapperFlow float-left"
            style={{ marginLeft: -20 }}
            onClick={() => {
              openLightbox("mpdn.png")
            }}
          >
            <img src={mpdn} />
          </div>
          <p>
            Блок МПД-Н применяется на тяговом и моторвагонном подвижном составе,
            эксплуатируемом на сети железных дорог с шириной колеи 1520 мм,
            электрифицированных на постоянном токе напряжением 3000 В и
            переменном токе напряжением 25000 В с частотой 50 Гц.
          </p>
          <p>
            Блок МПД-Н выпускается серийно по техническим условия и отвечает
            требованиям ГОСТ 15150-69, ГОСТ 17516.1-90, ГОСТ 33435-2015.
          </p>
          <p>
            Блок функционирует под управлением операционной системы на базе ядра
            Linux 3.10 с использование дополнительного прикладного программного
            обеспечения: СВЛ ТР, ViPNet Client 4 for Linux, КриптоПро CSP,
            PCSC-Lite, pppd, wvdial, ntpupdate и реализует:
          </p>
          <ul>
            <li>
              по интерфейсам стандарта CAN, последовательным интерфейсам
              стандартов RS-232 и RS-485, по интерфейсам стандарта Ethernet
              двусторонний информационный обмен с большинством серийно
              устанавливаемыми на отечественном подвижном составе локомотивными
              бортовыми программно-аппаратными комплексами управления
              локомотивом, обеспечения безопасности движения, автоведения,
              регистрации параметров;
            </li>
            <li>
              по мобильной связи стандарта GSM общего применения или по
              технологической ремонтно-оперативной радиосвязи ОАО «Российские
              железные дороги» на базе сетей подвижной связи стандарта GSM (РОРС
              GSM/GPRS) с поддержкой технологии пакетной передачи данных и
              скорости для GPRS (class 10), EDGE, UMTS, LTE и т.д., с
              обеспечением защиты передаваемых по радиоканалу данных от
              несанкционированного доступа с помощью средства криптографической
              защиты информации «Программный комплекс VipNet Client 4 for
              Linux», имеющего сертификат соответствия ФСБ России двусторонний
              информационный обмен с коммуникационным сервером, расположенным в
              ГВЦ ОАО «РЖД»;
            </li>
          </ul>
          <p>
            Мы готовы сконфигурировать вариант исполнения непосредственно под
            задачи заказчика – подобрать необходимые выводы интерфейсов,
            номинальное напряжение питания, а также доработать протокол обмена
            данными с устройствами или системами заказчика.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Область применения</td>
                <td>ТПС, МВПС</td>
              </tr>
              <tr>
                <td>Режим работы</td>
                <td>непрерывный, круглосуточный</td>
              </tr>
              <tr>
                <td>Диапазон напряжение питания постоянного тока, В</td>
                <td>
                  от 40 до 65 <br></br>от 77 до 137,0
                  <sup className="gray">1</sup>
                </td>
              </tr>
              <tr>
                <td>Средний потребляемый ток, А</td>
                <td>0,6</td>
              </tr>
              <tr>
                <td>Импульсный потребляемый ток, А</td>
                <td>1,5</td>
              </tr>
              <tr>
                <td>Волновое сопротивление нагрузки антенной цепи, Ом</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Допустимый диапазон рабочих температур, ºС</td>
                <td>от минус 50 до плюс 55</td>
              </tr>

              <tr>
                <td colSpan="2">Максимальная скорость передачи данных:</td>
              </tr>
              <tr>
                <td className="pl-6">– в режимах EDGE, UMTS, кбит/с</td>
                <td>384</td>
              </tr>
              <tr>
                <td className="pl-6">– в режиме HSPA, Мбит/с</td>
                <td>7,2</td>
              </tr>
              <tr>
                <td>Рабочие диапазоны частот, МГц</td>
                <td>от 850 до 900, от 1800 до 1900</td>
              </tr>
              <tr>
                <td>Режим передачи</td>
                <td>полудуплексный</td>
              </tr>
              <tr>
                <td colSpan="2">
                  Усреднённая точность получения навигационных параметров:
                </td>
              </tr>
              <tr>
                <td className="pl-6">– в плоскости, м</td>
                <td>1,5</td>
              </tr>
              <tr>
                <td className="pl-6">– высоты, м</td>
                <td>3</td>
              </tr>
              <tr>
                <td className="pl-6">– скорости, м/с</td>
                <td>0,05</td>
              </tr>
              <tr>
                <td className="pl-6">– времени, нс</td>
                <td>±25</td>
              </tr>
              <tr>
                <td>Наличие интерфейсов</td>
                <td>
                  CAN, RS-232, RS-485, Ethernet<sup className="gray">2</sup>
                </td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты связи</td>
                <td>
                  GSM 900 МГц, GSM 1800 МГц (WCDMA B8, LTE-FDD B1, LTE-FDD B3,
                  LTE-FDD B7, LTE-FDD B8, LTE-FDD B20, LTE CAT1, LTE CAT4)
                  <sup className="gray">3</sup>
                </td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты навигации</td>
                <td>ГЛОНАСС</td>
              </tr>
              <tr>
                <td>
                  Скорость работы по интерфейсам CAN, RS-232, RS-485, Ethernet
                </td>
                <td>регулируется встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>
                  Управление терминаторами 120 Ом шины CAN <sup>3</sup>
                </td>
                <td>подключение встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td>Скорость обмена по интерфейсу Ethernet, Мбит/сек</td>
                <td>
                  10<sup className="gray">2</sup>/100
                  <sup className="gray">2</sup>/1000
                  <sup className="gray">4</sup>
                </td>
              </tr>
              <tr>
                <td>Степень защиты корпуса</td>
                <td>IP53</td>
              </tr>
              <tr>
                <td>Средняя наработка на отказ, не менее, ч</td>
                <td>30 000</td>
              </tr>
              <tr>
                <td>Среднее время восстановления, не более, c</td>
                <td>60</td>
              </tr>

              <tr>
                <td>Габаритные размеры, не более, мм</td>
                <td>265 × 175 × 90 </td>
              </tr>
              <tr>
                <td>Вес, не более, кг</td>
                <td>3,0</td>
              </tr>
            </tbody>
          </table>
          <div className="table-desc">
            <div>
              1 – блоки исполнений ТИЖМ.467766.009-108, ТИЖМ.467766.009-400.01.
            </div>
            <div>
              2 – блоки исполнений ТИЖМ.467766.009-010, ТИЖМ.467766.009-010.01,
              ТИЖМ.467766.009-010.02, ТИЖМ.467766.009-010.04,
              ТИЖМ.467766.009-104.01, ТИЖМ.467766.009-201, ТИЖМ.467766.009-202.
            </div>
            <div>
              3 – блоки исполнений ТИЖМ.467766.009-010, ТИЖМ.467766.009-010.01,
              ТИЖМ.467766.009-010.02, ТИЖМ.467766.009-010.03,
              ТИЖМ.467766.009-010.04.
            </div>
            <div>
              4 – блоки исполнений ТИЖМ.467766.009-010, ТИЖМ.467766.009-010.01,
              ТИЖМ.467766.009-010.02.
            </div>
          </div>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}

import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import ServerImg from "../images/yenisei_s.png"
import ServerImgBox from "../images/yenisei_box.png"
import ServerBack from "../images/yenisei_back.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Yenisei() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">Сервер «Енисей»</h1>
          <div
            className="ImgWrapperFlow float-right"
            style={{ marginLeft: 30, marginTop: -40 }}
            onClick={() => {
              openLightbox("yenisei_l.png")
            }}
          >
            <img src={ServerImg} />
          </div>

          <p style={{ paddingTop: 45 }}>
            Сервер «Енисей» является безвентиляторным транспортным сервером
            промышленного исполнения и применяется на подвижном составе,
            эксплуатируемом на сети железных дорог.
          </p>

          <p>
            В настоящее время сервер «Енисей» входит в состав системы
            видеонаблюдения и оповещения пассажиров (СВОП) для электропоездов
            Ласточка.
          </p>
          <div className="clear-both"></div>
          <div
            className="ImgWrapperFlow float-left"
            style={{ marginLeft: -40, marginRight: 20 }}
            onClick={() => {
              openLightbox("yenisei_back.png")
            }}
          >
            <img src={ServerBack} />
          </div>
          <div style={{ marginTop: 70 }} className="margin-reset">
            Производство сервера осуществляется по техническим условиям
            ТИЖМ.466226.001 ТУ с применением импортонезависимых элементов.
          </div>
          <div className="clear-both pb-1"></div>

          <div
            className="ImgWrapperFlow float-right"
            style={{ marginLeft: 50, marginRight: 0 }}
            onClick={() => {
              openLightbox("yenisei_box.png")
            }}
          >
            <img src={ServerImgBox} />
          </div>
          <p className="pt-2">
            Аппаратная часть выполнена на импортонезависимой элементной базе.
          </p>
          <p>
            В качестве операционной системы сервера используется ОС Astra Linux,
            выполненная исключительно с применением сертифицированных
            компонентов программного обеспечения, разработанных в России.
          </p>
          <p>
            В зависимости от выполняемых задач возможно подобрать необходимые
            вычислительные мощности, выводы интерфейсов, количество слотов под
            жесткие диски и номинальное напряжение питания.
          </p>
          <p>Разработан и произведен в Москве.</p>
          <table className="tbl-params">
            <tbody>
              <tr>
                <td>Тип процессора</td>
                <td>64-bit Cortex-A55</td>
              </tr>
              <tr>
                <td>Тактовая частота, ГГц</td>
                <td>от 3 ГГц</td>
              </tr>
              <tr>
                <td>ОЗУ</td>
                <td>от 8Гб DDR4</td>
              </tr>
              <tr>
                <td>Внешние интерфейсы</td>
                <td></td>
              </tr>
              <tr>
                <td className="pl-6">– COM</td>
                <td>5шт / изолированные RS-232</td>
              </tr>
              <tr>
                <td className="pl-6">– USB</td>
                <td>2шт / USB 2.0; 1 / USB 3.0</td>
              </tr>
              <tr>
                <td className="pl-6">– Ethernet</td>
                <td>4шт / GE</td>
              </tr>
              <tr>
                <td className="pl-6">– Video</td>
                <td>VGA (DVI, HDMI)</td>
              </tr>
              <tr>
                <td className="pl-6">– Аudio</td>
                <td>микрофонный вход / линейный выход</td>
              </tr>
              <tr>
                <td>Наличие индикации состояния интерфейсов</td>
                <td>да / ЖКИ дисплей</td>
              </tr>
              <tr>
                <td>Номинальное напряжение питания постоянного тока</td>
                <td>24 / 50 / 75 / 110 В</td>
              </tr>
              <tr>
                <td>Номинальная потребляемая мощность</td>
                <td>45 Вт</td>
              </tr>
              <tr>
                <td>Тип охлаждения</td>
                <td>безвентиляторный с пассивным охлаждением</td>
              </tr>
              <tr>
                <td>Возможность крепления в стойке / стандарт</td>
                <td>да / ГОСТ 28601.1, ГОСТ 28601.2</td>
              </tr>
              <tr>
                <td>Диапазон рабочих температур</td>
                <td>от -40 до +55°С</td>
              </tr>
              <tr>
                <td>Диапазон температур хранения</td>
                <td>от -50 до +65°С</td>
              </tr>
              <tr>
                <td>Относительная влажность</td>
                <td>от 5 до 95% (без конденсации)</td>
              </tr>
              <tr>
                <td>Степень защиты корпуса</td>
                <td>IP40</td>
              </tr>
              <tr>
                <td>Габаритные размеры (ДШВ)</td>
                <td>300 × 200 × 84 мм</td>
              </tr>
              <tr>
                <td>Вес не более</td>
                <td>4,0 кг</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}

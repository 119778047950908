import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import blk2 from "../images/blk2_small.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Blk2() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">БЛК-2</h1>
          <div>
            <div
              className="ImgWrapperFlow float-left"
              style={{ marginLeft: -20 }}
              onClick={() => {
                openLightbox("blk2.png")
              }}
            >
              <img src={blk2} />
            </div>
            <p>
              БЛК-2 применяется как самостоятельное устройство, а также может
              обеспечивать взаимодействие различных бортовых
              программно-аппаратных комплексов с информационными системами
              железнодорожной отрасли.
            </p>
            <p>
              БЛК-2 применяется на тяговом и моторвагонном подвижном составе ОАО
              «Российские железные дороги», эксплуатируемом на сети железных
              дорог с шириной колеи 1520 мм, электрифицированных на постоянном
              токе напряжением 3000 В и переменном токе напряжением 25000 В с
              частотой 50 Гц.
            </p>
          </div>
          <p>
            БЛК-2 выпускается серийно по техническим условиям и отвечает
            требованиям ГОСТ 15150-69, ГОСТ 17516.1-90, ГОСТ 33435-2015.
          </p>
          <p className="clear-both">
            БЛК-2 функционирует под управлением операционной системы на базе
            ядра Linux 3.10 с использование дополнительных пакетов программного
            обеспечения: СВЛ ТР, pppd, wvdial, ntpupdate, ViPNet Client 4 for
            Linux, КриптоПро CSP, PCSC-Lite и реализует:
          </p>
          <ul>
            <li>
              по интерфейсам стандарта CAN (до 4 линий), Ethernet, по
              последовательному интерфейсу стандарта RS-232, по универсальному
              интерфейсу USB, по порту PS/2 обеспечивает возможность
              двустороннего информационного обмена с большинством серийно
              устанавливаемыми на отечественном подвижном составе локомотивными
              бортовыми программно-аппаратными комплексами обеспечения
              безопасности движения, автоведения, регистрации параметров;
            </li>
            <li>
              по мобильной связи GSM общего применения или по технологической
              ремонтно-оперативной радиосвязи ОАО «Российские железные дороги»
              на базе сетей подвижной связи стандарта GSM (РОРС GSM/GPRS) с
              поддержкой технологии пакетной передачи данных и скорости для GPRS
              (className 10), EDGE, UMTS, LTE и т.д., с обеспечением защиты
              передаваемых по радиоканалу данных от несанкционированного доступа
              с помощью средства криптографической защиты информации
              «Программный комплекс VipNet Client 4 for Linux», имеющего
              сертификат соответствия ФСБ России двусторонний информационный
              обмен с коммуникационным сервером, расположенным в ГВЦ ОАО «РЖД»;
            </li>
            <li>
              получение от спутниковых систем позиционирования ГЛОНАСС и GPS
              координат местонахождения устройства в пространстве и текущей
              скорости движения;
            </li>
            <li>
              удалённое обновление встроенного программного обеспечения
              бортового терминала БЛК-2 с коммуникационного сервера с
              обеспечением защиты информации от несанкционированного доступа с
              использованием СКЗИ – «Программный комплекс ViPNet Client 4 for
              Linux»;
            </li>
            <li>
              передача обновлений встроенного программного обеспечения и баз
              данных для блоков из состава бортовых локомотивных
              программно-аппаратных комплексов с коммуникационного сервера с
              обеспечением защиты информации от несанкционированного доступа с
              использованием СКЗИ – Программный комплекс ViPNet Client 4 for
              Linux»;
            </li>
            <li>
              чтение данных многофункциональной электронной карты ОАО «РЖД»
              посредством пакета КриптоПро CSP.
            </li>
            <li>голосовое оповещение о приближении к путевым объектам</li>
            <li>отображение данных АЛС</li>
            <li>электронный бортовой журнал ТУ-152 (опционально)</li>
          </ul>
          <p>
            В настоящее время в эксплуатации находится более 2500 тысяч
            устройств в различных вариантах исполнения.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Режим работы</td>
                <td>непрерывный, круглосуточный</td>
              </tr>
              <tr>
                <td>Диапазон питающего напряжения постоянного тока, В</td>
                <td>от 40 до 65</td>
              </tr>
              <tr>
                <td>Средний потребляемый ток, А</td>
                <td>0,6</td>
              </tr>
              <tr>
                <td>Импульсный потребляемый ток, не более, А</td>
                <td>1,5</td>
              </tr>
              <tr>
                <td>
                  Продолжительность бесперебойной работы при отсутствии внешнего
                  питания, не менее, сек
                </td>
                <td>60</td>
              </tr>
              <tr>
                <td>Волновое сопротивление нагрузки антенной цепи, Ом</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Допустимый диапазон рабочих температур, ºС</td>
                <td>от минус 40 до плюс 55</td>
              </tr>
              <tr>
                <td colSpan="2">Максимальная скорость передачи данных:</td>
              </tr>
              <tr>
                <td className="pl-6">– в режимах EDGE, UMTS, кбит/с</td>
                <td>384</td>
              </tr>
              <tr>
                <td className="pl-6">– в режиме HSPA, Мбит/с</td>
                <td>7,2</td>
              </tr>
              <tr>
                <td>Рабочие диапазоны частот, МГц</td>
                <td>от 850 до 900, от 1800 до 1900</td>
              </tr>
              <tr>
                <td>Режим передачи</td>
                <td>полудуплексный</td>
              </tr>
              <tr>
                <td colSpan="2">
                  Усреднённая точность получения навигационных параметров:
                </td>
              </tr>
              <tr>
                <td className="pl-6">– в плоскости, м</td>
                <td>2,5</td>
              </tr>
              <tr>
                <td className="pl-6">– высоты, м</td>
                <td>3</td>
              </tr>
              <tr>
                <td className="pl-6">– скорости, м/с</td>
                <td>0,05</td>
              </tr>
              <tr>
                <td className="pl-6">– времени, нс</td>
                <td>±25</td>
              </tr>
              <tr>
                <td>Скорость работы по интерфейсам CAN, RS-232</td>
                <td>регулируется встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td>Внешние интерфейсы (количество)*</td>
                <td>CAN (до 3), RS-232, USB, PS/2</td>
              </tr>
              <tr>
                <td>Степень защиты у корпуса</td>
                <td>IP50</td>
              </tr>
              <tr>
                <td>Размер экране по диагонали, дюйм</td>
                <td>12,1</td>
              </tr>
              <tr>
                <td>Разрешение экрана</td>
                <td>1024 х 768</td>
              </tr>
              <tr>
                <td>Габаритные размеры (Д x Ш x В), не более, мм</td>
                <td>310,0 × 225,0 × 95,0</td>
              </tr>
              <tr>
                <td>Средний срок службы, не менее, лет</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Средняя наработка на отказ, не менее, ч</td>
                <td>30 000</td>
              </tr>
              <tr>
                <td>Среднее время восстановления, не более, c</td>
                <td>120</td>
              </tr>
              <tr>
                <td>Вес, не более, кг</td>
                <td>8,0</td>
              </tr>
            </tbody>
          </table>
          <p>* - наличие и количество зависит от исполнения</p>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}

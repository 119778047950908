import React from "react"
import tw from "twin.macro"
import styled from "styled-components/macro"
import { Layout } from "../components/Layout"
import Yenisei from "../data/products/components/Yenisei"
import Blk2 from "../data/products/components/Blk2"
import Mpd from "../data/products/components/Mpd"
import Mpd010 from "../data/products/components/Mpd010"
import Mpdn from "../data/products/components/Mpdn"
import Tsn from "../data/products/components/Tsn"

export const TeplateStyled = styled.div`
  h1 {
    ${tw`text-xl font-display font-semibold text-blue-500 mb-3 flex`}
    &:before {
      ${tw`w-0 md:w-1/3 md:mr-4`}
      content: "";
    }
  }
  .Main {
    ${tw`flex flex-wrap w-full mt-4 md:flex-no-wrap`}
  }
  .LeftCol {
    ${tw`w-full mb-4 md:w-1/3 md:mr-4 md:mb-0`}
  }
  .ImgWrapper {
    ${tw`rounded-lg overflow-hidden py-4 bg-bg`}
  }
  .RightCol {
    ${tw`w-full text-sm text-justify md:w-2/3`}
  }

  .MainNew {
    ${tw`flex flex-wrap w-full mt-1 md:flex-no-wrap`}
  }

  .__react_modal_image__icon_menu a {
    padding: 10px 5px 0px 5px !important;
  }

  .float-clear {
    clear: both;
  }

  .ProdWrapper {
    ${tw`w-full text-sm text-justify md:w-3/5 m-auto`}
  }

  .mt-1 {
    margin-top: 10px;
  }

  .mt-5 {
    margin-top: 50px;
  }
  .pt-2 {
    padding-top: 20px;
  }

  .mt-6 {
    margin-top: 60px;
  }
  .d-block {
    ${tw`block`}
  }
  .float-left {
    ${tw`float-left`}
  }

  .float-right {
    ${tw`float-right`}
  }

  .ImgWrapperFlow {
    ${tw`rounded-lg overflow-hidden pb-1 bg-bg md:w-1/2 cursor-pointer`}
  }
  .ImgWrapperFlow img {
    object-fit: cover;
    transition: 0.5s ease all;
  }

  .ImgWrapperFlow:hover img {
    transform: scale(1.05);
  }

  .ImgWrapperHover:hover img {
    transform: scale(1.05);
  }

  .ImgWrapperHover img {
    object-fit: cover;
    transition: 0.5s ease all;
  }

  p {
    ${tw`mb-2`}
    text-indent: 2em;
  }
  ul {
    ${tw`list-disc list-inside mb-2`}
  }
  table {
    ${tw`table-auto w-full mt-6`}
    tr:nth-child(2n-1) {
      background: #ccc;
    }
    thead tr {
      background: none !important;
    }
    td {
      ${tw`px-6 py-1`}
    }
    td:nth-child(2) {
      ${tw`text-blue-500`}
    }
  }
`

const Products = {
  yenisei: Yenisei,
  blk2: Blk2,
  mpd: Mpd,
  mpdn010: Mpd010,
  mpdn: Mpdn,
  tsn: Tsn,
}

export default function ProductTemplate({ pageContext }) {
  const { html, slug } = pageContext

  const ObjectType = Products[slug]

  return (
    <Layout>
      <ObjectType />
    </Layout>
  )
}
